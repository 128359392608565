import { useCallback, useEffect, useRef, useState } from 'react'
import dayjs from 'date'
import equal from 'fast-deep-equal'
import { resolveRef } from 'helpers/getters'

import { useTestDiff } from 'layers/immortals/pages/HomePage/components/Counter/util/useCountdown'


const formatTime = (time: number) => Number(time.toString()) < 10 ? `0${time}` : `${time}`


const handleGetTargetDate = (testDiff) => {
  const now = dayjs().add(testDiff, 'seconds')
  const thisYear = dayjs(now).format('YYYY')

  const date = dayjs(`09.05.${thisYear} 10:00`, 'DD.MM.YYYY HH:mm') > now
    ? dayjs(`09.05.${thisYear} 10:00`, 'DD.MM.YYYY HH:mm')
    : dayjs(`09.05.${Number(thisYear) + 1} 10:00`, 'DD.MM.YYYY HH:mm')

  const isExpired = (
    dayjs(`09.05.${thisYear} 10:00`, 'DD.MM.YYYY HH:mm') <= now
    && dayjs(`10.05.${thisYear}`, 'DD.MM.YYYY') > now
  )

  return {
    date,
    isExpired,
  }
}

const declOfNum = (n, text_forms) => {
  n = Math.abs(n) % 100
  let n1 = n % 10
  if (n > 10 && n < 20) { return text_forms[2] }
  if (n1 > 1 && n1 < 5) { return text_forms[1] }
  if (n1 === 1) { return text_forms[0] }
  return text_forms[2]
}

const useCountdown = () => {
  const testDiff = useTestDiff()

  const [ isExpired, setExpired ] = useState(() => handleGetTargetDate(testDiff).isExpired)

  const daysRef = useRef<HTMLDivElement>()

  const getTargetDate = useCallback(() => {
    const { date, isExpired } = handleGetTargetDate(testDiff)

    if (isExpired) {
      setExpired(true)
    }

    return date
  }, [ testDiff ])

  const timeLeftRef = useRef({})

  const getTimeLeft = useCallback(() => {
    const now = dayjs().add(testDiff, 'seconds')
    const diff = getTargetDate().diff(now)
    const timeLeft = dayjs.duration(diff)

    const timeLeftData = {
      days: Math.floor(timeLeft.asDays()),
      hours: timeLeft.hours(),
      minutes: timeLeft.minutes() + 1,
    }

    const isEqual = equal(timeLeftData, timeLeftRef.current)

    timeLeftRef.current = timeLeftData

    return {
      data: timeLeftData,
      isEqual,
    }
  }, [ testDiff, getTargetDate ])

  const renderTimer = useCallback(({ days, hours, minutes }) => {
    if (!isExpired) {
      Promise.all([
        resolveRef(daysRef),
      ])
        .then(() => {
          const text = days
            ? declOfNum(days, [ 'день', 'дня', 'дней' ])
            : hours
              ? declOfNum(hours, [ 'час', 'часа', 'часов' ])
              : declOfNum(minutes, [ 'минута', 'минуты', 'минут' ])

          daysRef.current.innerHTML = days
            ? `${days} ${text}`
            : hours
              ? `${hours} ${text}`
              : `${minutes} ${text}`
        })
    }
  }, [ daysRef, isExpired ])

  useEffect(() => {
    const tick = (force?: boolean) => {
      const { data: { days, hours, minutes }, isEqual } = getTimeLeft()

      if (!isEqual || force) {
        renderTimer({ days, hours, minutes })
      }
    }

    const forceRender = () => tick(true)

    window.addEventListener('resize', forceRender)

    const interval = setInterval(tick, 1000)

    return () => {
      clearInterval(interval)
      window.removeEventListener('resize', forceRender)
    }
  }, [ renderTimer, getTimeLeft ])

  return {
    counterRefs: {
      daysRef,
    },
    isExpired,
  }
}


export default useCountdown
