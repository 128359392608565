import React, { useMemo } from 'react'
import cx from 'classnames'
import links from 'links'
import { useDevice } from 'device'
import { useModuleTitle } from 'models/settings'

import { Href } from 'components/navigation'
import { Image, Text } from 'components/dataDisplay'

import useBannerCountdown from 'layers/immortals/pages/components/ImmortalsBanner/util/useBannerCountdown'

import s from './ImmortalsBanner.scss'

import logoImage from './images/logo.svg'
import polkDesktopImage from './images/polk-desktop.png'
import polkTabletImage from './images/polk-tablet.png'
import polkMobileImage from './images/polk-mobile.png'


type ImmortalsBannerProps = {
  className?: string
}

const ImmortalsBanner: React.FC<ImmortalsBannerProps> = (props) => {
  const { className } = props
  const { isMobile, isTablet } = useDevice()
  const { counterRefs, isExpired } = useBannerCountdown()

  const { polkImage, height } = useMemo(() => {
    if (isMobile) {
      return {
        polkImage: polkMobileImage,
        height: '176',
      }
    }
    if (isTablet) {
      return {
        polkImage: polkTabletImage,
        height: '204',
      }
    }

    return {
      polkImage: polkDesktopImage,
      height: '204',
    }
  }, [ isMobile, isTablet ])

  // const isOnline = useMemo(() => {
  //   const now = dayjs().add(testDiff, 'seconds')
  //   const year = now.format('YYYY')
  //   const start = dayjs(`09.05.${year} 10:00`, 'DD.MM.YYYY HH:mm')
  //   const end = dayjs(`10.05.${year}`, 'DD.MM.YYYY')
  //
  //   return now >= start && now <= end
  // }, [ testDiff ])

  // const daysLeft = useMemo(() => {
  //   const now = dayjs().add(testDiff, 'seconds')
  //   const year = now.format('YYYY')
  //   const start = dayjs(`09.05.${year} 10:00`, 'DD.MM.YYYY HH:mm')
  //   const diff = start.diff(now, 'day', false)
  //   const text = declOfNum(diff, [ 'день', 'дня', 'дней' ])
  //
  //   return `${Math.floor(diff)} ${text}`
  // }, [ testDiff ])

  const { title } = useModuleTitle('IMMORTALS')

  return (
    <Href className={cx(className, 'bg-jaws-warrior radius-24 block')} to={links.immortals.root}>
      <div className="flex">
        <div className="flex-1 pl-24px pt-16px pb-24px">
          <div className="flex items-center">
            <Image
              src={logoImage}
              width={isMobile ? '32' : '56'}
            />
            <div className={cx(s.line, 'ml-16px bg-lebowski')} />
            <div className="ml-16px">
              {
                isExpired ? (
                  <Text
                    message="Онлайн"
                    size="c20"
                    color="lebowski"
                  />
                ) : (
                  <>
                    <Text
                      className="opacity-48"
                      message="До шествия"
                      size="s13-r"
                      color="schindler"
                    />
                    <Text
                      ref={counterRefs.daysRef}
                      className={s.text}
                      message=""
                      size="c20"
                      color="lebowski"
                    />
                  </>
                )
              }
            </div>
          </div>
          <Text
            className="mt-12px overflow-ellipsis webkit-box"
            message={title}
            size={isMobile ? 'h20-28' : 'h32'}
            color="platoon"
          />
          <Text
            className="mt-12px overflow-ellipsis-2 webkit-box"
            message={`
              Они должны идти победным
              строем в любые времена
            `}
            size={isMobile ? 't16-20' : 't20-24'}
            color="platoon"
          />
        </div>
        <Image
          className={cx('ml-8px', s.polkImage)}
          src={polkImage}
          height={height}
        />
      </div>
    </Href>
  )
}


export default ImmortalsBanner
